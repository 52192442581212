// Custom directives
import { defineNuxtPlugin } from '#app'

export const clickOutsideDirective = {
  mounted: function (el, binding, vNode) {
    const bubble = binding.modifiers.bubble

    const handler = e => {
      if (!e.target.hasAttribute('ignore-click-outside') &&
        !e.target.closest('[ignore-click-outside]') &&
        (bubble || (!el.contains(e.target) && el !== e.target))) {
        binding.value(e)
      }
    }

    el.clickOutside = handler
    document.addEventListener('click', handler)
  },
  unmounted: function (el, binding) {
    document.removeEventListener('click', el.clickOutside)
    el.clickOutside = null
  },
}

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    // Directive for handling click outside of component
    nuxtApp.vueApp.directive('click-outside', clickOutsideDirective)
  },
})
