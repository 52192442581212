import { gql } from '@apollo/client/core'

export const jobRecommendationRequest = gql`
  query listJobs($specialties: [String], $brand: String, $page: String, $paginationSize: String, $states: [String],$licensedStates:[String], $imlcParticipant: Boolean, $sortType:String, $portalExclusiveJobParticipant:Boolean,$locationFilterType:String,$geolocation:String, $preferredStates:[String]) {
    listJobs(specialties: $specialties, brand: $brand, page: $page, paginationSize: $paginationSize, states: $states,licensedStates:$licensedStates, imlcParticipant: $imlcParticipant, sortType:$sortType, portalExclusiveJobParticipant:$portalExclusiveJobParticipant,locationFilterType:$locationFilterType,geolocation:$geolocation, preferredStates:$preferredStates) {
      jobs {
        id,
        title,
        description,
        division,
        emailEligible,
        postEligible,
        postDate,
        displayLocation,
        state,
        benefits,
        quickFacts,
        specialties,
        updatedBy,
        createdBy,
        status,
        updatedDate,
        createdDate,
        startDate,
        endDate,
        createdAt,
        updatedAt,
        locationLabel,
        Interests {
          type
        },
        salaryLow,
        salaryHigh,
        salaryUnit,
        isIMLC,
        disclaimer,
        portalExclusiveJob,
      },
      allJobsCount,
      statesResult {
        state,
        stateCount
      }
    }
  }
`

export const getProviderPreferences = gql`
  query requestPreferences($brand: String) {
    requestPreferences(brand: $brand) {
      sort,
      specialtyCodes,
      states,
    }
  }
`
export const listCitiesOrZipCodes = gql`
  query listCitiesOrZipCodes($searchString: String) {
    listCitiesOrZipCodes(searchString: $searchString) {
        location,
        city,
        zipCode,
        state,
    }
  }
`

export default {
  jobRecommendationRequest,
  getProviderPreferences,
  listCitiesOrZipCodes,
}
